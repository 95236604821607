import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    data: {
      title: 'Home',
      icon: '',
      showInMenu: false
    },
  },
  {
    path: 'associazione',
    loadComponent: () => import('./pages/associazione/associazione.page').then(m => m.AssociazionePage),
    data: {
      title: 'Associazione',
      icon: 'book-outline',
      showInMenu: true
    },
  },
  {
    path: 'eventi',
    loadComponent: () => import('./pages/eventi/eventi.page').then(m => m.EventiPage),
    data: {
      title: 'Eventi',
      icon: 'calendar-outline',
      showInMenu: true
    },
  },
  {
    path: 'menu',
    loadComponent: () => import('./pages/menu/menu.page').then(m => m.MenuPage),
    data: {
      title: 'Menù',
      icon: 'fast-food-outline',
      showInMenu: true
    },
  },
  {
    path: 'prenota',
    loadComponent: () => import('./pages/prenota/prenota.page').then(m => m.PrenotaPage),
    data: {
      title: 'Prenota',
      icon: 'call-outline',
      showInMenu: true
    },
  },
  {
    path: 'organizza',
    loadComponent: () => import('./pages/organizza/organizza.page').then(m => m.OrganizzaPage),
    data: {
      title: 'Organizza',
      icon: 'megaphone-outline',
      showInMenu: true
    },
  },
  {
    path: 'contatti',
    loadComponent: () => import('./pages/contatti/contatti.page').then(m => m.ContattiPage),
    data: {
      title: 'Contatti',
      icon: 'mail-open-outline',
      showInMenu: true
    },
  },
  // {
  //   path: 'gallery',
  //   loadComponent: () => import('./pages/gallery/gallery.page').then(m => m.GalleryPage),
  //   data: {
  //     title: 'Gallery',
  //     icon: 'images-outline',
  //     showInMenu: true
  //   },
  // },
  // {
  //   path: 'giochi',
  //   loadComponent: () => import('./pages/giochi/giochi.page').then(m => m.GiochiPage),
  //   data: {
  //     title: 'Giochi',
  //     icon: 'dice-outline',
  //     showInMenu: true
  //   },
  // },

  // {
  //   path: 'soon',
  //   loadComponent: () => import('./pages/soon/soon.page').then(m => m.SoonPage)
  // },
  {
    path: 'odin_menu.pdf',
    loadComponent: () => import('./components/pdf-viewer/pdf-viewer.component').then(m => m.PdfViewerComponent),
    data: {
      title: 'Menu PDF',
      showInMenu: false
    },
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/register/register.page').then(m => m.RegisterPage)
  },
  {
    path: 'admin',
    loadComponent: () => import('./pages/admin/admin.page').then(m => m.AdminPage),
    canActivate: [AuthGuard],
    data: {
      ruolo: ['Admin'],
      title: 'Admin',
      icon: 'settings-outline',
      showInMenu: true
    },
  },
  {
    path: 'admin-eventi',
    loadComponent: () => import('./pages/admin-eventi/admin-eventi.page').then(m => m.AdminEventiPage),
    canActivate: [AuthGuard],
    data: { ruolo: ['Admin'] },
  },
  {
    path: 'admin-utenti',
    loadComponent: () => import('./pages/admin-utenti/admin-utenti.page').then(m => m.AdminUtentiPage),
    canActivate: [AuthGuard],
    data: { ruolo: ['Admin'] },
  },
  {
    path: 'verify-email',
    loadComponent: () => import('./pages/verify-email/verify-email.page').then( m => m.VerifyEmailPage)
  },
  {
    path: 'admin-giochi',
    loadComponent: () => import('./pages/admin-giochi/admin-giochi.page').then( m => m.AdminGiochiPage)
  },
];
